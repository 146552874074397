<!-- 语法 -->
<template>
    <div class="">
        <yitable :data="[data0,data1,data2,data3]"></yitable>
    </div>
</template>

<script>
    import axios from 'axios'
    import yitable from "@/components/yilou/table.vue";
    export default {
        name: '',
        components: {
            yitable,
        },
        data() { // 数据源
            return {
                data0:[],
                data1:[],
                data2:[],
                data3:[],
            }
        },
        mounted() {
            this.mfd()
            this.mfz()
            this.mf2()
            this.mf4()
        },
        computed: {

        },
        methods: { // 函数体部分，js的主要逻辑控制   
            mfd() {//走势图
                document.documentElement.scrollTop = 0;
                axios({
                    method: "post",
                    url: "https://sd-ycl.com/lot/td",
                    //data: {  record: 50}
                    //params: { record: 50 }
                }).then(  
                    (res) => {
                        var data0 = []
                        var x = res.data
                        x.forEach((item) => {
                            var obj = {
                                'number': item.match(/Code=(\S*), /)[1],
                                'yilou': parseInt(item.match(/id1=(\S*)}/)[1])-parseInt(item.match(/id2=(\S*) /)[1])
                            }
                            data0.push(obj)
                        })
                        this.data0=data0
                      
                    },
                )
            },
            mfz() {//走势图
                axios({
                    method: "post",
                    url: "https://sd-ycl.com/lot/tz",
                    //data: {  record: 50}
                    //params: { record: 50 }
                }).then(  
                    (res) => {
                        var data0 = []
                        var x = res.data
                        x.forEach((item) => {
                            var obj = {
                                'number': item.match(/Code=(\S*), /)[1],
                                'yilou': parseInt(item.match(/id1=(\S*)}/)[1])-parseInt(item.match(/id2=(\S*) /)[1])
                            }
                            data0.push(obj)
                        })
                        this.data1=data0
                    },
                )
            },
            mf2() {//走势图
                axios({
                    method: "post",
                    url: "https://sd-ycl.com/lot/t2",
                    //data: {  record: 50}
                    //params: { record: 50 }
                }).then(  
                    (res) => {
                        var data0 = []
                        var x = res.data
                        x.forEach((item) => {
                            var obj = {
                                'number': item.match(/Code=(\S*), /)[1],
                                'yilou': parseInt(item.match(/id1=(\S*)}/)[1])-parseInt(item.match(/id2=(\S*) /)[1])
                            }
                            data0.push(obj)
                        })
                        this.data2=data0
                    },
                )
            },
            mf4() {//走势图
                axios({
                    method: "post",
                    url: "https://sd-ycl.com/lot/t4",
                    //data: {  record: 50}
                    //params: { record: 50 }
                }).then(  
                    (res) => {
                        var data0 = []
                        var x = res.data
                        x.forEach((item) => {
                            var obj = {
                                'number': item.match(/Code=(\S*), /)[1],
                                'yilou': parseInt(item.match(/id1=(\S*)}/)[1])-parseInt(item.match(/id2=(\S*) /)[1])
                            }
                            data0.push(obj)
                        })
                        this.data3=data0
                    },
                )
            },
        },
        props: {
        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
</style>